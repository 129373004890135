/* eslint-disable */
import Button from '@common_button';
import Dialog from '@common_dialog';
import Skeleton from '@common_skeleton';
import Radio from '@common_forms/Radio';
import Add from '@heroicons/react/24/outline/PlusIcon';
import AddressFormDialog from '@plugin_addressform';
import Typography from '@common_typography';
import ItemAddress from '@core_modules/checkout/pages/default/components/ItemModalAddress';
import AddressForm from '@core_modules/customer/plugins/AddressForm';
import cx from 'classnames';

const Loader = () => (
    <div className="flex flex-col mt-4 gap-4 w-full">
        {[1, 2].map((key) => (
            <Skeleton variant="rect" width="100%" height={118} key={key} />
        ))}
    </div>
);

const AddressView = (props) => {
    const {
        t, open, setOpen, loading, success,
        tempSelectedAddress, address, handleAddress, selectedAddressId, loadingAddress,
        handleTempSelectAddress, handleUpdateAddress, handleEdit, handleCloseDiff, handleOpenNew, openNew, typeAddress, setTypeAddress, dataEdit,
        updateAddress, manageCustomer, refetchAddress,
    } = props;
    const getItemAddress = () => {
        let content;
        if (loading) {
            content = <Loader />;
        } else if (!address) {
            content = <Loader />;
        } else if (address.length === 0) {
            content = null;
        } else {
            content = (
                <Radio
                    data={address}
                    CustomItem={ItemAddress}
                    onChange={(e) => handleTempSelectAddress(e)}
                    customItemProps={{
                        t,
                        manageCustomer,
                        loadingAddress,
                        success,
                        handleOpenNew,
                        handleEdit,
                        updateAddress,
                        handleCloseDiff,
                        selectedAddressId: tempSelectedAddress,
                        refetchAddress,
                        handleTempSelectAddress,
                        // ...((typeAddress === 'update' && openNew) && dataEdit), 
                    }}
                />
            );
        }

        return content;
    };

    const addressListProps = {
        classContainerAction: 'w-full',
        positiveLabel: 'Change Shipping Address',
        positiveAction: handleUpdateAddress,
        positiveProps: {
            className: 'w-full',
        },
        negativeLabel: 'Cancel',
        negativeAction: () => setOpen(false),
        negativeProps: {
            className: 'w-full',
        },
    };

    return (
        <>
            {/* <AddressFormDialog
                {...props}
                open={openNew}
                onSubmitAddress={handleAddress}
                loading={loadingAddress}
                success={success}
                setOpen={() => handleOpenNew(typeAddress)}
                pageTitle={typeAddress === 'new' ? t('customer:address:addTitle') : t('customer:address:editTitle')}
                {...dataEdit}
                identifier="swift-form-address-customer"
            /> */}
            <Dialog
                open={open}
                title={t('customer:address:pageTitle')}
                onClickCloseTitle={() => setOpen(false)}
                useCloseTitleButton
                onClose={() => setOpen(false)}
                classWrapper="swift-modal-address-list"
                classContent="!px-6"
                {...(!openNew && addressListProps)}
                // content={(
                //     <div className="flex flex-col h-[502px]" id="checkoutListAddress">
                //         <div className="overflow-y-auto mb-6">
                //             {getItemAddress()}
                //         </div>
                //         <div className="w-full flex flex-row items-center justify-center">
                //             <Button
                //                 variant="outlined"
                //                 size="sm"
                //                 onClick={() => handleOpenNew('new')}
                //                 className="swift-add-btn-new-address w-full"
                //                 classNameText="mx-auto w-fit"
                //             >
                //                 <Typography variant="bd-2" className="capitalize" type="bold">
                //                     {t('customer:address:addTitle')}
                //                 </Typography>
                //             </Button>
                //         </div>
                //     </div>
                // )}
                content={openNew ? (
                    <AddressForm
                        t={t}
                        onSubmitAddress={handleAddress}
                        loading={loadingAddress}
                        success={success}
                        pageTitle={t('checkout:address:addTitle')}
                        identifier="swift-form-address-guest"
                        fieldWrapperClass="max-h-[calc(90vh_-_166px)] overflow-y-auto py-6"
                        formClass="!gap-0"
                        {...(typeAddress === 'update' && openNew && {
                            ...dataEdit,
                            addressId: dataEdit?.id,
                            country: {
                                id: dataEdit?.country?.code,
                                full_name_locale: dataEdit?.country?.label,
                            },
                            region: dataEdit?.region?.region,
                            street: Array.isArray(dataEdit?.street) ? dataEdit?.street[0] : dataEdit?.street,
                            defaultShipping: dataEdit?.default_shipping,
                            defaultBilling: dataEdit?.default_billing
                        })}
                        CustomAction={(
                            <div className={cx(
                                'w-[calc(100%_+_48px)]',
                                '-mx-6',
                                'pt-6',
                                'px-6',
                                'border-t-[7px]',
                                'border-[#E8E8E8]',
                                'flex',
                                'gap-[18px]',
                                // classContainerAction,
                            )}
                            >
                                <Button
                                    variant="outlined"
                                    onClick={() => {
                                        handleOpenNew(false);
                                    }}
                                    className="negative-action w-full py-[12px] px-[22px]"
                                    classNameText="justify-center"
                                    disabled={loading}
                                    // {...negativeProps}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    loading={loading}
                                    disabled={loading}
                                    type="submit"
                                    className={
                                        cx(
                                            'positive-action w-full py-[12px] px-[22px] border-0 mobile:w-[50%] desktop:w-auto tablet:w-auto',
                                            '!w-[100%]',
                                        )
                                    }
                                    classNameText="justify-center"
                                    // {...positiveProps}
                                >
                                    {typeAddress === 'update' ? 'Update Address' : 'Add Address'}
                                </Button>
                            </div>
                        )}
                    />
                ) : (
                    <div className="flex flex-col max-h-[calc(90vh_-_232px)]" id="checkoutListAddress">
                        <div className="overflow-y-auto mb-6">
                            {getItemAddress()}
                        </div>
                        <div className="w-full mb-6 flex flex-row items-center justify-center">
                            <Button
                                variant="outlined"
                                size="sm"
                                onClick={() => handleOpenNew('new')}
                                className="swift-add-btn-new-address w-full"
                                classNameText="mx-auto w-fit"
                            >
                                <Typography variant="bd-2" className="capitalize" type="bold">
                                    {t('customer:address:addTitle')}
                                </Typography>
                            </Button>
                        </div>
                    </div>
                )}
            />
        </>
    );
};

export default AddressView;
