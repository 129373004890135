import Typography from '@common_typography';
import Thumbor from '@common_image';
import { formatPrice } from '@helper_currency';
import cx from 'classnames';
import Show from '@common/Show';
import parser from 'html-react-parser';
import Badge from '@common/Badge';
import Accordion from '@common/Accordion';
import Skeleton from '@common/Skeleton';

const CartItems = (props) => {
    const {
        t, storeConfig, checkout, currencyCache,
    } = props;
    const cartItems = checkout?.data?.cart?.items;

    if (checkout.loading.all) {
        return <Skeleton className="rounded-[50%] mb-[5px]" width="100%" height={72} />;
    }

    return (
        <Accordion
            className="bg-neutral-white rounded-lg mt-3 p-6"
            label={`My Cart (${cartItems?.length})`}
            classLabel="!text-2xl text-[#161616] font-semibold"
        >
            <div>
                {cartItems && cartItems.map((item, index) => (
                    <div className={cx('flex flex-col gap-3')}>
                        <div className={cx('flex flex-row items-start gap-3', 'relative py-[18px] border-t border-[#D2D2D2]')} key={index}>
                            <div className="relative">
                                <Thumbor
                                    className="!w-[82px] !h-[82px]"
                                    classContainer="!w-[82px] !h-[82px] border border-[#DFDFDF] rounded-lg"
                                    src={item.product.small_image.url}
                                    alt={item.product.name}
                                    width={105}
                                    height={105}
                                    storeConfig={storeConfig}
                                />
                                <Show when={item.prices.row_total.value === 0}>
                                    <Badge fontSize={10} success label={t('common:title:free')} className="absolute top-1 left-1" />
                                </Show>
                            </div>
                            <div className={cx('flex flex-col')}>
                                <div className="flex flex-col">
                                    <Typography size="14" className="!leading-[22.4px] mb-[2px] !font-normal" color="text=[#535353]">
                                        {item.product.product_code}
                                    </Typography>
                                    <Typography
                                        variant="bd-2b"
                                        className="line-clamp-2 !font-medium text-base !leading-[19.6px]"
                                        color="text-[#101828]"
                                    >
                                        {parser(item.product.name)}
                                    </Typography>
                                    {item.configurable_options && item.configurable_options.length ? (
                                        <div className="my-1 flex flex-col">
                                            {item.configurable_options.map((val, idx) => (
                                                <Typography variant="bd-3b" key={idx}>
                                                    <strong>{val.option_label}</strong>
                                                    {' '}
                                                    :
                                                    {val.value_label}
                                                </Typography>
                                            ))}
                                        </div>
                                    ) : null}
                                </div>
                                <div className="flex items-center justify-between mt-3">
                                    <Typography variant="bd-2b" size="14" className="!font-semibold !leading-[22.4px]" color="text=[#151515]">
                                        {item.prices.row_total.value === 0
                                            ? t('common:title:free')
                                            : formatPrice(
                                                item.prices.row_total.value,
                                                item.prices.row_total.currency || 'IDR',
                                                currencyCache,
                                            )}
                                    </Typography>
                                    <div className="bg-cloudy-10 min-w-8 h-8 rounded-[5px] flex items-center justify-center">
                                        <span className="font-medium leading-[20px] text-base text-[#262626]">{item.quantity}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </Accordion>
    );
};

export default CartItems;
