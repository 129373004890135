import Typography from '@common/Typography';
import Skeleton from '@common_skeleton';

const Loader = () => (
    <>
        <Skeleton width="100%" height={10} />
        <Skeleton width="100%" height={10} />
        <Skeleton width="100%" height={10} />
    </>
);

const SelectedAddress = (props) => {
    const { address, loading, isGuest = false } = props;
    if (!address) {
        return null;
    }
    if (loading) {
        return <Loader />;
    }

    if (isGuest) {
        return (
            <div className="mb-[17px]">
                <Typography className="text-lg font-semibold" color="text-[#262626]">
                    {address.firstname}
                    {' '}
                    {address.lastname}
                </Typography>
                <div className="flex flex-col my-2">
                    <Typography className="text-base font-semibold" color="text-[#161616]">Address</Typography>
                    <Typography className="font-medium" color="text-[#5B5B5B]">{address.street}</Typography>
                </div>
                <div className="flex items-start gap-2">
                    <div className="flex flex-col w-[calc(50%_-_4px)]">
                        <Typography className="text-base font-semibold" color="text-[#161616]">Postal Code</Typography>
                        <Typography className="font-medium" color="text-[#5B5B5B]">{address.postcode}</Typography>
                    </div>
                    <div className="flex flex-col w-[calc(50%_-_4px)]">
                        <Typography className="text-base font-semibold" color="text-[#161616]">Phone Number</Typography>
                        <Typography className="font-medium" color="text-[#5B5B5B]">{address.telephone}</Typography>
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className="relative mb-[17px] border border-cloudy-40 p-3 rounded-xl">
            <Typography
                className="py-[2px] px-2.5 bg-blue-10 rounded-2xl font-medium !leading-6 absolute top-3 right-3"
                color="text-blue-100"
            >
                Default
            </Typography>
            <Typography className="text-lg font-semibold" color="text-[#262626]">
                {address.firstname}
                {' '}
                {address.lastname}
            </Typography>
            <Typography className="text-lg font-normal" color="text-[#262626]">
                {' '}
                {address.telephone}
            </Typography>
            <div className="flex flex-col my-2">
                <Typography className="font-medium !leading-5" color="text-[#5B5B5B]">{address.street}</Typography>
            </div>
        </div>
    );
};

export default SelectedAddress;
