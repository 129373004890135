import Typography from '@common_typography';
import React from 'react';
// import AddressFormDialog from '@plugin_addressform';
import Radio from '@common/Forms/Radio';
import Button from '@common/Button';
import cx from 'classnames';

const ItemAddress = (props) => {
    const {
        firstname = '',
        lastname = '',
        street = '',
        // postcode = '',
        // country = '',
        // region = '',
        // city = '',
        telephone = '',
        default_shipping = false,
        // default_billing = false,
        value = '',
        checked = false,
        // loading,
        // open,
        // setOpen,
        // handleSave,
        // success,
        id,
        t,
        onChange,
        handleEdit,
        // eslint-disable-next-line no-unused-vars
    } = props;
    // const region_value = region.region;
    // const street_value = street[0];
    return (
        <>
            <div
                className={cx(
                    'flex flex-col checkoutListItemAddress border-b border-neutral-200',
                    'pt-6 pb-[18px]',
                )}
            >
                <Radio
                    variant="single"
                    id={id}
                    checked={checked}
                    value={value}
                    onClick={() => {
                        if (onChange) {
                            onChange({
                                target: {
                                    value: id,
                                },
                            });
                        }
                    }}
                    className="flex flex-row items-center"
                >
                    <div className="w-full pb-2 flex flex-col ml-1 mr-4">
                        <label htmlFor={id} className="flex flex-col cursor-pointer">
                            {default_shipping && (
                                <div className="flex gap-1 mb-1">
                                    <Typography className="py-[2px] px-2.5 bg-blue-10 rounded-2xl font-medium !leading-6" color="text-blue-100">
                                        Default
                                    </Typography>
                                </div>
                            )}
                            <div className="mb-1.5">
                                <Typography color="text-[#262626]" className="text-lg leading-[25px] font-semibold">
                                    {`${firstname} ${lastname} `}
                                </Typography>
                                <Typography color="text-[#262626]" className="text-lg leading-[25px] font-normal">
                                    {telephone}
                                </Typography>
                            </div>
                            <Typography className="!leading-[17px] font-normal" color="text-[#101828]">
                                {street}
                            </Typography>
                        </label>
                    </div>
                    <Button variant="plain" onClick={() => handleEdit(id)} className="!p-0">
                        <Typography className="mt-3 cursor-pointer" variant="bd-2">
                            {t('common:button:edit')}
                        </Typography>
                    </Button>
                </Radio>
            </div>
        </>
    );
};

export default ItemAddress;
