/* eslint-disable max-len */
/* eslint-disable semi-style */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
import React from 'react';

import Button from '@common_button';
import Radio from '@common_forms/Radio';
import Typography from '@common_typography';
import commonConfig from '@config';
import FieldPoint from '@core_modules/checkout/components/fieldcode';
import RadioItem from '@core_modules/checkout/pages/default/components/payment/components/RadioItem';
import ModalHowtoPay from '@core_modules/checkout/pages/default/components/ModalHowtoPay';
import StripeCheckoutForm from '@core_modules/checkout/pages/default/components/payment/components/StripeCheckoutForm';
import Skeleton from '@common_skeleton';
import classNames from 'classnames';

import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { useSelector } from 'react-redux';
import {
    selectCheckoutState,
} from '@core_modules/checkout/redux/checkoutSlice';

const PO = 'purchaseorder';
const PaypalCode = 'paypal_express';
const stripePayments = 'stripe_payments';

/**
 * Loader
 * @returns {COMPONENT}
 */
const Loader = () => (
    <>
        <Skeleton className="rounded-[50%] mb-[10px]" width="100%" height={20} />
        <Skeleton className="rounded-[50%] mb-[10px]" width="100%" height={20} />
        <Skeleton className="rounded-[50%] mb-[10px]" width="100%" height={20} />
    </>
);

// const PaymentGroupIcon = (props) => {
//     const { baseMediaUrl, src } = props;
//     const fallbacks = [`${baseMediaUrl}checkout/payment/paymenticons-${src.replace('pg-', '')}.svg`, null];

//     // check if image exist on the backoffice, otherwise use fallback image from PWA
//     const [imageSrc, setImageSrc] = React.useState(`${basePath}/assets/img/paymenticons-${src.replace('pg-', '')}.svg`);
//     const [fallbackImageIndex, setFallbackImageIndex] = React.useState(0);

//     // set image fallback url
//     const getFallbackImageSrc = () => {
//         if (fallbackImageIndex > fallbacks.length) {
//             return;
//         }
//         setImageSrc(fallbacks[fallbackImageIndex]);
//         setFallbackImageIndex(fallbackImageIndex + 1);
//     };

//     return (
//         <>
//             {(imageSrc && (
//                 <img
//                     className="w-11 h-11 mr-3"
//                     src={imageSrc}
//                     alt={src.replace('pg-', '')}
//                     onError={() => getFallbackImageSrc()}
//                 />
//             ))
//                 || ''}
//         </>
//     );
// };

/**
 * [VIEW] Payment
 * @param {object} props
 * @returns
 */
const PaymentView = (props) => {
    const {
        loading,
        data,
        clientSecret,
        t,
        paymentMethodList,
        handlePayment,
        handlePurchaseOrder,
        handlePurchaseOrderSubmit,
        selected,
        paypalTokenData,
        paypalHandlingProps,
        initialOptionPaypal,
        storeConfig,
        displayHowToPay,
        setDisplayHowToPay,
    } = props;

    const checkout = useSelector(selectCheckoutState);

    const { modules } = commonConfig;
    // const [expanded, setExpanded] = React.useState(null);
    const [openModal, setModal] = React.useState(false);
    const [stripePromise, setStripePromise] = React.useState(null);

    React.useEffect(() => {
        if (storeConfig && storeConfig.stripe_config && storeConfig.stripe_config.stripe_enable
            && storeConfig.stripe_config.test_pk && storeConfig.stripe_config.stripe_mode === 'test') {
            const key = storeConfig.stripe_config.test_pk;
            setStripePromise(loadStripe(key));
        } else if (storeConfig && storeConfig.stripe_config && storeConfig.stripe_config.stripe_enable
            && storeConfig.stripe_config.live_pk && storeConfig.stripe_config.stripe_mode === 'live') {
            const key = storeConfig.stripe_config.live_pk;
            setStripePromise(loadStripe(key));
        }
    }, []);

    let content;

    /**
     * [METHOD] handle modal
     * @param {*} state
     */
    const handleModal = (state = false) => {
        setModal(state);
    };

    /**
     * [MAIN] return of view
     */
    if (loading.payment || loading.all) {
        content = <Loader />;
    } else if (data.cart.prices.grand_total.value === 0) {
        content = <Typography>{t('checkout:noNeedPayment')}</Typography>;
    } else if (data.paymentMethod.length !== 0 && paymentMethodList && paymentMethodList.storeConfig) {
        let paymentConfig = JSON.parse(`${paymentMethodList.storeConfig.payments_configuration}`);
        const groups = paymentConfig ? Object.keys(paymentConfig) : [];
        // create grouping by config
        paymentConfig = groups.map((key) => {
            const groupData = [];
            let config = paymentConfig[key];
            config = config.split(',');
            for (let idx = 0; idx < data.paymentMethod.length; idx++) {
                const element = data.paymentMethod[idx];
                for (let idc = 0; idc < config.length; idc++) {
                    if (config[idc] === element.code) {
                        groupData.push(element);
                    }
                }
            }
            let active = false;
            if (groupData.length > 0) {
                // ad active key if on group data selected payment method
                if (selected.payment) {
                    for (let idx = 0; idx < groupData.length; idx += 1) {
                        const element = groupData[idx];
                        if (element.code === selected.payment) {
                            active = true;
                        }
                    }
                }
            }
            return {
                group: key,
                data: groupData,
                active,
            };
        });

        // check if have active on group data by default selected if
        // let itemActive = false;
        if (paymentConfig) {
            for (let idx = 0; idx < paymentConfig.length; idx += 1) {
                const element = paymentConfig[idx];
                if (element.active) {
                    // itemActive = true;
                }
            }
        }
        content = (
            <div>
                {paymentConfig && (
                    <div className="mt-4 flex gap-[100px]">
                        {paymentConfig.map((item, index) => {
                            if (item.data.length !== 0) {
                                let groupLabel;
                                if (item.group === 'pg-creditcard') {
                                    groupLabel = 'Credit/Debit Card';
                                } else if (item.group === 'pg-banktransfer') {
                                    groupLabel = 'Bank Transfer';
                                } else if (item.group === 'pg-ewallet') {
                                    groupLabel = 'E - Wallet';
                                } else {
                                    groupLabel = t(`checkout:paymentGrouping:${item.group.replace('pg-', '')}`) === `paymentGrouping.${item.group.replace('pg-', '')}`
                                        ? item.group.replace('pg-', '')
                                        : t(`checkout:paymentGrouping:${item.group.replace('pg-', '')}`);
                                }
                                return (
                                    <div>
                                        <div className="mb-3">
                                            {/* <PaymentGroupIcon src={item.group} baseMediaUrl={storeConfig.base_media_url} /> */}
                                            <Typography className="block font-semibold" variant="bd-2" color="text-[#161616]">
                                                {groupLabel}
                                            </Typography>
                                        </div>
                                        <div className="flex flex-col">
                                            <Radio
                                                key={`${index}-${item.group}`}
                                                value={selected.payment}
                                                onChange={handlePayment}
                                                data={item.data}
                                                CustomItem={RadioItem}
                                                ComponentOptional={(item) => {
                                                    // prettier-ignore
                                                    const isPurchaseOrder = item.code === PO && selected.payment === PO;
                                                    const isPaypal = item.code === PaypalCode && selected.payment === PaypalCode;
                                                    const isStripe = item.code === stripePayments && selected.payment === stripePayments;
                                                    // eslint-disable-next-line max-len

                                                    if (isPurchaseOrder) {
                                                        return (
                                                            <FieldPoint
                                                                id="purchase-order"
                                                                name="purchase-order"
                                                                placeholder={t('checkout:purchaseOrderNumber')}
                                                                action={handlePurchaseOrderSubmit}
                                                                onChange={handlePurchaseOrder}
                                                                value={checkout.selected.purchaseOrderNumber || ''}
                                                                disabled={checkout.loading.purchaseOrderNumber}
                                                                loading={checkout.loading.purchaseOrderNumber}
                                                                styleFrame={{ marginTop: 0, marginBottom: 0 }}
                                                                styleFrameText={{ marginTop: 0, marginBottom: 0 }}
                                                                styleTextField={{ marginTop: 0, marginBottom: 0 }}
                                                            />
                                                        );
                                                    }
                                                    if (
                                                        isPaypal
                                                                    && !paypalTokenData.loading
                                                                    && initialOptionPaypal['data-order-id'] !== ''
                                                    ) {
                                                        return (
                                                            <PayPalScriptProvider defer options={initialOptionPaypal}>
                                                                <PayPalButtons
                                                                    style={{ layout: 'horizontal' }}
                                                                    {...paypalHandlingProps}
                                                                />
                                                            </PayPalScriptProvider>
                                                        );
                                                    }
                                                    if (isStripe
                                                                    && storeConfig
                                                                    && storeConfig.stripe_config
                                                                    && storeConfig.stripe_config.stripe_enable
                                                                    && (storeConfig.stripe_config.live_pk || storeConfig.stripe_config.test_pk)
                                                    ) {
                                                        return (
                                                            <>
                                                                {stripePromise && clientSecret && (
                                                                    <Elements
                                                                        stripe={stripePromise}
                                                                        options={{ clientSecret }}
                                                                    >
                                                                        <StripeCheckoutForm {...props} />
                                                                    </Elements>
                                                                )}
                                                            </>
                                                        );
                                                    }

                                                    return null;
                                                }}
                                                propsItem={{
                                                    borderBottom: false,
                                                    classContent: '',
                                                    RightComponent: true,
                                                }}
                                                className="!mb-0"
                                                classNames={{
                                                    radioGroupClasses: item.data.length > 3 ? '!gap-y-3 !gap-x-8 !grid !grid-cols-2' : '!gap-3 flex flex-col',
                                                }}
                                                size="sm"
                                                disabled={loading.order || loading.all}
                                            />
                                        </div>
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                )}
            </div>
        );
    } else if (checkout.selected.delivery === 'pickup') {
        content = <Typography>{t('checkout:noPaymentPickup')}</Typography>;
    } else {
        content = <Typography>{t('checkout:noPayment')}</Typography>;
    }

    return (
        <div
            id="checkoutPayment"
            className={classNames(
                'flex flex-col ',
                'w-full',
            )}
        >
            <ModalHowtoPay
                open={openModal}
                setOpen={() => handleModal(false)}
                setDisplayHowToPay={setDisplayHowToPay}
            />
            <div className="flex flex-row justify-between items-start">
                <div>
                    <Typography variant="h2" className="font-semibold capitalize" color="text-[#161616]">
                        {t('checkout:payment')}
                    </Typography>
                    <Typography className="font-normal" color="text-[#626262]">
                        Choose your payment method
                    </Typography>
                </div>
                {(modules.checkout.howtoPay.enabled && displayHowToPay) ? (
                    <div>
                        <Button
                            className="px-2 py-2 swift-action-howtopay"
                            onClick={() => handleModal(true)}
                            disabled={loading.order || loading.all}
                        >
                            {t('checkout:howtoPay')}
                        </Button>
                    </div>
                ) : null}
            </div>
            <div>{content}</div>
            <div className="mt-[47px]">
                <Typography className="block mb-2 leading-[17.8px] font-normal" color="text-[#626262]">You will be directed to the payment page. After the payment process is successful, you will receive an email for your order.</Typography>
                <div className="bg-[#F2F2F2] p-6 rounded-xl">
                    <Typography className="block font-semibold text-lg mb-1" color="text-[#161616]">Attention!</Typography>
                    <Typography className="block leading-[17.8px] font-normal" color="text-[#626262]">You will be directed to the payment page via a secure internet communication channel.The purchase process uses online payment with a VISA or MASTERCARD credit card, or a debit card from the ATM Bersama, PRIMA and ALTO bank networks. After the payment process is successful, you will receive an email with a list of orders and payments that you have made.</Typography>
                    <Typography className="block leading-[17.8px] font-normal" color="text-[#161616]"> Please do not close your browser window until online payment has been successfully carried out! Goods that have been purchased cannot be exchanged or returned</Typography>
                </div>
            </div>
        </div>
    );
};

export default PaymentView;
