import Button from '@common/Button';
import classNames from 'classnames';

const StepContainer = ({
    number,
    title,
    subtitle,
    active,
    completed,
    className = '',
    showBack = false,
    showNext = false,
    handleBack = () => {},
    handleNext = () => {},
    backActionClassName,
    nextActionClassName,
    backLabel,
    nextLabel,
    disableBack,
    disableNext,
    children,
    CustomNextAction,
}) => (
    <div className={classNames(
        'step-container py-4 px-6 rounded-xl',
        (!completed && !active) ? 'border border-[#C7D0DD] !bg-transparent' : 'bg-neutral-white',
        !active && 'pointer-events-none',
        className,
    )}
    >
        <div className={classNames(
            'step-header flex items-center gap-3 mb-[17px]',
            (!completed && !active) && 'text-cloudy-80 !mb-0',
        )}
        >
            <span className={classNames(
                'step-number',
                'block w-8 h-8 rounded-full border border-neutral-black',
                'flex items-center justify-center',
                'font-semibold text-lg leading-6',
            )}
            >
                {number}
            </span>
            <div>
                <h3 className="step-title font-semibold text-xl leading-7">{title}</h3>
                <p className="step-subtitle text-cloudy-100 leading-[22px]">{subtitle}</p>
            </div>
        </div>
        <div>
            {(active || completed) && children}
            {active && (
                <div className="step-actions mt-6 flex gap-6">
                    {showBack && (
                        <Button
                            onClick={handleBack}
                            className={classNames('step-back px-[51px]', backActionClassName)}
                            classNameText="!font-semibold text-center !block mx-auto"
                            variant="outlined"
                            disabled={disableBack}
                        >
                            {backLabel || 'Back'}
                        </Button>
                    )}
                    {CustomNextAction && React.isValidElement(CustomNextAction) ? (
                        React.cloneElement(CustomNextAction)
                    ) : (
                        <>
                            {showNext && (
                                <Button
                                    onClick={handleNext}
                                    className={classNames('step-next w-full', nextActionClassName)}
                                    classNameText="text-center !block mx-auto"
                                    disabled={disableNext}
                                >
                                    {nextLabel || 'Next'}
                                </Button>
                            )}
                        </>
                    )}
                </div>
            )}
        </div>
    </div>
);

export default StepContainer;
